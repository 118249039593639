<script setup>
import { reactive } from 'vue'
import { useSidePanel } from '@/stores/sidepanel'
import SidePanelMenu from '@/components/layout/panel/SidePanelMenu.vue'

const sidePanelStore = useSidePanel()

const items = reactive([
  {
    label: 'Requests',
    icon: 'file-pen',
    tab: 'requests'
  },
  {
    label: 'Client details',
    icon: 'house-user',
    tab: 'details'
  },
  {
    label: 'Estimates and projects',
    icon: 'columns-3',
    tab: 'pipeline'
  },
  {
    label: 'Rating',
    icon: 'star',
    tab: 'rating'
  },
  {
    label: 'History',
    icon: 'clock-rotate-left',
    tab: 'activities'
  }
])
</script>

<template>
  <div class="w-full h-screen flex flex-col">
    <div
      class="md:hidden px-4 h-[60px] w-full m-0 flex flex-row justify-between items-center border-b border-cement-600"
    >
      <span class="text-[20px] semi-bold">Menu</span>
      <font-awesome-icon
        icon="times"
        size="lg"
        class="cursor-pointer"
        @click.native="sidePanelStore.toggle"
      />
    </div>
    <SidePanelMenu :model="items" :allowStarred="false" />
  </div>
</template>
